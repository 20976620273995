import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: 'admin/base/login',
    method: 'post',
    data: data
  })
}

export function logout() {
  return request({
    url: 'admin/base/logout',
    method: 'post'
  })
}

// 字段获取
export function filterIndexfields(data) {
  return request({
    url: 'crmManagement/AdminField/getField',
    method: 'post',
    data: data
  })
}

// 场景获取
export function crmSceneIndex(data) {
  return request({
    url: 'crmManagement/AdminScene/crmSceneList',
    method: 'post',
    data: data
  })
}

// 获取字段操作记录
export function crmIndexFieldRecord(data) {
  return request({
    url: 'crmManagement/AdminActionRecord/fieldRecord',
    method: 'post',
    data
  })
}

// 获取跟进记录列表
export function crmRecordIndex(data) {
  return request({
    url: 'crmManagement/AdminRecord/recordList',
    method: 'post',
    data
  })
}

// 相关团队列表
export function crmSettingTeam(query) {
  return request({
    url: 'crmManagement/setting/team',
    method: 'get',
    params: query
  })
}

// 设置团队列表
export function crmSettingTeamSave(data) {
  return request({
    url: 'crmManagement/setting/teamSave',
    method: 'post',
    data: data
  })
}

// 附件列表
export function crmFileIndex(data) {
  return request({
    url: 'admin/file/index',
    method: 'post',
    data: data
  })
}

/**
 *
 * @param {*} data
 * 附件上传 file module module_id
 */
export const crmFileSaveUrl = 'file/file/save'
export function crmFileSave(data) {
  var param = new FormData()
  Object.keys(data).forEach(key => {
    param.append(key, data[key])
  })
  return request({
    url: 'file/file/save',
    method: 'post',
    data: param,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 附件删除接口
export function crmFileDelete(data) {
  return request({
    url: 'file/file/delete',
    method: 'post',
    data: data
  })
}

// 保存跟进记录
export function crmRecordSave(data) {
  return request({
    url: 'crmManagement/AdminRecord/saveRecord',
    method: 'post',
    data
  })
}
// 保存跟进记录评论
export function crmRecordComment(data) {
  return request({
    url: 'crmManagement/AdminRecord/addComment',
    method: 'post',
    data
  })
}

// 获取配置
export function crmSettingRecordListAPI(data) {
  return request({
    url: 'crmManagement/CrmConfig/getConfig',
    method: 'post',
    data
  })
}

// 获取用户列表
export function usersList(data) {
  return request({
    url: 'admin/adminUser/getUserList',
    method: 'post',
    data: data
  })
}

// 自选审批人列表
export function crmExamineFlowUserList() {}

// 获取通用配置列表
export function crmGenConfig(query) {
  return request({
    url: 'common/Crmconfig/getList',
    method: 'get',
    query
  })
}

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: 'admin/base/user',
    method: 'post',
    data
  })
}

// 用户编辑
export function adminUsersEdit(data) {
  return request({
    url: 'admin/AdminUser/wapEditUser',
    method: 'post',
    data
  })
}
// 获取阿里云oss配置
export function ossConfig(data) {
  return request({
    url: 'common/Crmconfig/ossConfig',
    method: 'post',
    data: data,
    loading: false
  })
}
// 保存客户端文件上传信息
export function saveFileInfo(data) {
  return request({
    url: 'file/file/saveFileInfo',
    method: 'post',
    data: data,
    loading: false
  })
}

// 根据部门获取用户
export function getUserByStructure(data) {
  return request({
    url: 'biManagement/Common/getUserByStructure',
    method: 'post',
    data: data
  })
}
/**
 *
 * 获取渠道归类列表信息
 * @param {*} data
 * @returns
 */
export function AdminStructureChannelGetList(data) {
  return request({
    url: 'oaManagement/AdminStructureChannel/getList',
    method: 'post',
    data
  })
}

// 登录用户获取权限菜单
export function getRoles(params) {
  return request({
    url: 'admin/SystemRoles/getRoles',
    method: 'get',
    params
  })
}

// 登录用户角色
export function getAccessRole(params) {
  return request({
    url: 'crmManagement/CrmReceivablesClaim/getAccessRole',
    method: 'get',
    params
  })
}

// 获取公司用户
export function getCompanyUsers(data) {
  return request({
    url: 'admin/adminUser/getCompanyUsers',
    method: 'post',
    data: data
  })
}

// 上门列表
export function oaVisitGetList(data) {
  return request({
    url: 'oaManagement/OaVisit/getList',
    method: 'post',
    data
  })
}

// 添加上门
export function oaVisitSetVisit(data) {
  return request({
    url: 'oaManagement/OaVisit/setVisit',
    method: 'post',
    data
  })
}

// 快速编辑
export function fastEdit(data) {
  return request({
    url: 'oaManagement/OaVisit/fastEdit',
    method: 'post',
    data
  })
}

// 编辑
export function visitEdit(data) {
  return request({
    url: 'oaManagement/OaVisit/edit',
    method: 'post',
    data
  })
}
