// set function formatTime to filter
import { timestampToFormatTime, formatTimeToTimestamp } from '@/utils'

/**
 * 时间戳到格式化时间
 * {{item.create_time|filterTimestampToFormatTime('MM-DD dddd')}}
 * @param {*} time
 * @param {*} cFormat
 */
export function filterTimestampToFormatTime(time, cFormat) {
  if (!cFormat) {
    cFormat = 'YYYY-MM-DD HH:mm'
  }
  if (!time || time == 0) {
    return ''
  }
  return timestampToFormatTime(time, cFormat)
}

/** 格式化时间到时间戳 */
export function filterFormatTimeToTimestamp(format) {
  return formatTimeToTimestamp(format)
}

//格式化标准时间
export function handleDate(date) {
  //创建补0函数
  function fix(s) {
    return s < 10 ? '0' + s : s
  }
  //创建、格式化时间函数
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hour = date.getHours()
  let minute = date.getMinutes()
  //格式化时间
  let newDate = year + '-' + fix(month) + '-' + fix(day) + ' ' + fix(hour) + ':' + fix(minute)
  return newDate
}

/** 用户头像的占位图标 */
export function filterUserLazyImg(value) {
  return {
    src: value,
    error: require('@/assets/img/head.png'),
    loading: require('@/assets/img/loading.gif')
  }
}

// 通过审批状态
export function commonCheckStatus(value, type = 'label') {
  const checkArray = [
    { label: '待审核', value: 0, type: 'warning' },
    { label: '审核中', value: 1, type: 'primary' },
    { label: '审核通过', value: 2, type: 'success' },
    { label: '审核拒绝', value: 3, type: 'danger' },
    { label: '撤销', value: 4, type: 'default' },
    { label: '草稿', value: 5, type: 'default' },
    { label: '作废', value: 6, type: 'default' }
  ]
  var returnStr = ''
  checkArray.forEach(item => {
    if (item.value == value) {
      if (type == 'label') {
        returnStr = item.label
      } else if (type == 'type') {
        returnStr = item.type
      }
    }
  })
  return returnStr
}

// 通过开票状态
export function commonBillingStatus(value, type = 'label') {
  const checkArray = [
    { label: '待开票', value: 0, type: 'primary' },
    { label: '已开票', value: 1, type: 'success' },
    { label: '申请中', value: 2, type: 'warning' }
  ]
  var returnStr = ''
  checkArray.forEach(item => {
    if (item.value == value) {
      if (type == 'label') {
        returnStr = item.label
      } else if (type == 'type') {
        returnStr = item.type
      }
    }
  })
  return returnStr
}
