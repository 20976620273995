import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import system from './modules/system'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name
  },
  mutations: {},
  actions: {},
  modules: { user, system }
})
