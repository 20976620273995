import axios from 'axios'
import { Dialog, Toast, Notify } from 'vant'
import store from '@/store'
import { getToken } from '@/utils/auth'

window.BASE_URL = process.env.VUE_APP_BASE_API

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000, // request timeout
  headers: {
    platform: 'h5'
  }
})

function tryHideFullScreenLoading() {
  Toast.clear()
}

function startLoading() {
  Toast({
    type: 'loading',
    duration: 0,
    forbidClick: true,
    message: '加载中...',
    loadingType: 'spinner'
  })
}

var hasLoading = true

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (!config.loading || config.loading !== false) {
      startLoading()
    } else {
      hasLoading = false
    }

    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    if (hasLoading !== false) {
      tryHideFullScreenLoading()
    }
    // return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (hasLoading !== false) {
      tryHideFullScreenLoading()
    }

    const res = response.data
    if ('arraybuffer' == response.request.responseType) {
      return response
    }
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      // 50000 token错误
      if (res.code === 50000) {
        // to re-login
        Dialog.confirm({
          title: '系统提示',
          message: res.message
        })
          .then(() => {
            store.dispatch('user/logout').then(() => {
              location.reload()
            })
          })
          .catch(() => {
            // on cancel
          })
      }
      // Toast.fail(res.message || 'Error')
      Notify({ type: 'danger', message: res.message || 'Error' })
      // return Promise.reject(new Error(res.message || 'Error'))
      return Promise.resolve(res)
    } else {
      return res
    }
  },
  error => {
    if (hasLoading !== false) {
      tryHideFullScreenLoading()
    }
    console.log('err' + error) // for debug
    // Toast.fail(error.message || 'Error')
    Notify({ type: 'danger', message: error.message || 'Error' })
    // return Promise.reject(error)
  }
)

export default service
