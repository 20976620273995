import { crmGenConfig, getRoles } from '@/api/common'

const state = {
  crm_config: '',
  crm_role_config: '',
  crm_role_config_button: ''
}

const mutations = {
  SET_CRM_CONFIG: (state, data) => {
    state.crm_config = data
  },
  SET_CRM_ROLE_CONFIG: (state, data) => {
    state.crm_role_config = data
  },
  SET_CRM_ROLE_BUTTON_CONFIG: (state, data) => {
    state.crm_role_config_button = data
  }
}

const actions = {
  // 获取通用配置
  crmGenConfig({ commit, state }) {
    return new Promise((resolve, reject) => {
      crmGenConfig()
        .then(response => {
          const { data } = response
          if (!data) {
            reject('通用配置获取失败')
          }

          var object = {}
          data.forEach(item => {
            object[item.name] = item.value
          })
          commit('SET_CRM_CONFIG', object)
          resolve(object)
        })
        .catch(error => {
          reject(error)
        })
      getRoles()
        .then(response => {
          const { data } = response
          if (!data) {
            reject('角色获取失败')
          }

          const { buttons, menus } = data
          commit('SET_CRM_ROLE_BUTTON_CONFIG', buttons)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
